<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="false"
      :pagination-options="{
        enabled: false,
        perPage: 100,
        mode: 'pages',
      }"
      @on-selected-rows-change="selectionChanged"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'Satır Secildi.',
        clearSelectionText: 'Temizle',
      }"
      :search-options="{ enabled: false }"
      styleClass="vgt-table striped bordered"
    >
      <div slot="selected-row-actions">
        <b-button squared variant="info" v-b-tooltip.hover="'Toplu İşlem'" @click="handlerTopluGuncelle">
          <i class="fad fa-th-list" />
        </b-button>
        <b-button squared variant="secondary" v-b-tooltip.hover="'Kopyala'" @click="handlerUrunKopyala">
          <i class="fad fa-copy" />
        </b-button>
        <b-button squared variant="danger" v-b-tooltip.hover="'Toplu Sil'" @click="handlerTopluSil">
          <i class="fad fa-trash" />
        </b-button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'baslik'">
          <router-link
            :to="{
              name: 'urun-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="font-weight-bold d-block text-dark">
              {{ props.row.icerik[defaultDil].baslik }}
            </span>
            <span class="d-block text-dark">
              {{ props.row.urun_kodu }} |
              <span class="text-danger" v-if="props.row.marka">{{ props.row.marka[defaultDil].baslik }} </span>
            </span>
          </router-link>
        </span>
        <span class="d-flex align-items-center" v-else-if="props.column.field === 'dil' && diller.length > 1">
          <b-badge :variant="langColor(dil)" class="rounded-0 mr-1" v-for="(dil, i) in props.row.icerik" :key="i">
            {{ i }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'kategori'">
          <b-badge
            variant="primary"
            class="rounded-0 font-weight-light"
            style="font-size: 14px"
            v-if="props.row.kategori != null"
          >
            {{ props.row.kategori[defaultDil].baslik }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'fiyat'">
          <b-badge variant="danger" class="rounded-0"> Kdv : {{ props.row.kdv_oran | cur }} %</b-badge>
          <b-badge variant="warning" class="rounded-0"> Alış : {{ props.row.alis_fiyat | cur }} {{ props.row.kur_turu }}</b-badge>
          <b-badge variant="primary" class="rounded-0">
            Satış : {{ props.row.satis_fiyat | cur }} {{ props.row.kur_turu }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'stok_risk_adet'">
          <b-badge
            :variant="props.row.bakiye > 0 ? 'success' : 'danger'"
            class="rounded-0 font-weight-light"
            style="font-size: 14px"
          >
            {{ props.row.stok_risk_adet }} {{ props.row.stok_birim && props.row.stok_birim[defaultDil].baslik }}
          </b-badge>
          {{ props.row.stok_risk }}
        </span>
        <span v-else-if="props.column.field === 'stok'">
          <b-badge variant="danger" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{ props.row.bakiye }} {{ props.row.stok_birim && props.row.stok_birim[defaultDil].baslik }}
          </b-badge>
          {{ props.row.stok_risk }}
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-badge
            :variant="props.row.statu == 'yayinda' ? 'success' : props.row.statu == 'yayin_disi' ? 'danger' : 'warning'"
            class="rounded-0 d-block font-weight-light"
            style="font-size: 14px"
          >
            {{ props.row.statu == 'yayinda' ? 'Yayında' : props.row.statu == 'yayin_disi' ? 'Yayın Dışı' : 'Taslak' }}
          </b-badge>

          <b-badge class="mt-2 rounded-0 d-block" :variant="props.row.satis_durum ? 'success' : 'danger'">
            {{ props.row.satis_durum ? 'Satışa Açık' : 'Satışa Kapalı' }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'vitrin'">
          <span
            class="d-block font-weight-bold"
            style="font-size: 12px"
            v-for="(vit, index) in props.row.vitrin_gosterim"
            :key="index"
          >
            {{ vitrinText(vit) }}</span
          >
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="primary"
              v-b-tooltip.hover.top="'Ürün Hareketleri'"
              :to="{
                name: 'urun-hareketler',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="info"
              v-b-tooltip.hover.top="'Açılış Kartı Ekle'"
              @click="handlerAcilisKart(props.row)"
            >
              <i class="fad fa-layer-group" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="warning"
              v-b-tooltip.hover.top="'Güncelle'"
              :to="{
                name: 'urun-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button variant="danger" class="rounded-0" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.count = ref(0);
    expo.filter = ref({
      page: 0,
      pageSize: 20,
    });
    expo.columns = ref([
      {
        label: 'Ürün Bilgisi',
        field: 'baslik',
      },
      {
        label: 'Kategori',
        field: 'kategori',
        width: '12%',
      },
      {
        label: 'Risk Adet',
        field: 'stok_risk_adet',
        width: '8%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Stok',
        field: 'stok',
        width: '8%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Durum',
        field: 'statu',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);

    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('urunStokRiskRaporuListele', expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getStokRiskRaporu;
          expo.count.value = expo.rows.value.length;
          context.emit('total', expo.count.value);
        }
      });
      context.emit('show', false);
    };
    handlerFetchAllData();

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
